import styled from '@emotion/styled';
import { backgroundImage, color } from 'styled-system';
import { SerializedStyles } from '@emotion/serialize';
import { fullWidth as fullWidthCss } from '../../../../../shared-styles/full-width.css';
import { extendStyles } from '../../../../../utils/extendStyles.function';
import { ExtendableEmotion } from '../../../../../helpers/ExtendableEmotion.interface';

export interface PageSectionStyledPropsI extends ExtendableEmotion {
  /** Component props */

  fullWidth?: boolean;
  paddingTop?: number;
  paddingBottom?: number;
  style?: any;
  /** styled-system props */

  backgroundColor?: string;
  backgroundImage?: string;
  className?: string;
}

export const PageSectionStyled = styled.section<PageSectionStyledPropsI>`
  ${color}
  ${backgroundImage}

  ${(props): SerializedStyles | string => (props.fullWidth ? fullWidthCss : '')}

  padding-top: ${(props): SerializedStyles | string => (props.paddingTop ? `${props.paddingTop}px;` : `${props.fullWidth ? '' : ''}`)};

  padding-bottom: ${(props): SerializedStyles | string =>
    props.paddingBottom ? `${props.paddingBottom}px;` : `${props.fullWidth ? '' : ''}`};

  ${(props): SerializedStyles | string => extendStyles(props.extendStyles)}
`;
