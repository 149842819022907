import { css } from '@emotion/core';
import { theme } from '../../../../layouts/main.layout';

export const postContentCss = css`
  p,
  ul,
  li {
    font-size: 16px;
    line-height: 1.3;

    a {
      font-size: 16px;
    }
  }

  p {
    margin: 20px 0;
  }

  a {
    font-weight: bold;
    color: ${theme.colors.vozziOrange};
    text-decoration: underline;
    position: relative;
  }

  h1,
  h2 {
    margin-top: 50px;
    margin-bottom: 30px;
  }

  h3,
  h4,
  h5 {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 26px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  img {
    margin-top: 0px;
    margin-bottom: 0px;
    max-width: 100%;
    height: auto;
  }

  figure {
    margin: 0;
  }

  .embed-container {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  div.wp-block-buttons {
    margin-top: 40px;
  }

  div.wp-block-button {
    margin-top: 20px;
    a {
      padding: 15px;
      text-decoration: none;
    }
  }

  blockquote,
  .instagram-media {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }

  @media screen and (max-width: 780px) {
    p {
      line-height: 1.5;
    }
  }
`;
