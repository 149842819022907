import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';

import PageNotFound from '../../pages_/404/404.page';
import { COUNTRY } from '../../configs/env';
import { PageContext } from '../../types/types';
import { css } from '../../helpers/css.theme';
import { PageSection } from '../../components/layout/_/PageSection/PageSection.component';
import { postContentCss } from '../blog/single/components/postContent.css';
import { NewStandardPageLayout } from '../../layouts/newStadardPage.layout';
import { Container } from '../../layouts/main.layout';

interface WpPageNodeI {
  node: {
    id: string;
    slug: string;
    date: string;
    title: string;
    content: string;
  };
}

interface TermsPageDataPropsI {
  data: {
    allWordpressPage: {
      edges: Array<WpPageNodeI>;
    };
  };
  pageContext: PageContext;
}

type TermsPageType = (props: TermsPageDataPropsI) => ReactElement;

const TermsPage: TermsPageType = (props) => {
  useEffect(() => {
    if (COUNTRY !== 'eu') {
      // ReactDOM.render(
      //   <>
      //     <InjectedLink />
      //     <span>.</span>
      //   </>,
      //   document.querySelector('#general-privacy-link')
      // );
    }
  });

  const { t } = useTranslation();
  const lang = props.pageContext.lang;
  const meta = {
    title: t('meta_TermsOfUseTitle'),
    description: t('meta_TermsOfUseDescription'),
  };

  const generalTermsRS = props.data.allWordpressPage.edges.find((edge) => edge.node.slug === `uslovi-koriscenja-sr-rs`);
  const generalTermsRO = props.data.allWordpressPage.edges.find((edge) => edge.node.slug === `ro-terms-of-use-10-2022`);

  const generalTermsBG = props.data.allWordpressPage.edges.find((edge) => edge.node.slug === `bg-terms-of-use`);

  const generalTerms = props.data.allWordpressPage.edges.find((edge) => edge.node.slug === `uslovi-koriscenja-en-24-10-2022`);

  if (generalTermsRS && ((COUNTRY === 'rs' && lang === 'sr') || (COUNTRY === 'me' && lang === 'me'))) {
    return (
      <NewStandardPageLayout
        lang={props.pageContext.lang}
        mainHeading={t('tos_MainHeading')}
        subText={t('tos_MainSubText')}
        backgroundImage="bg/packages.jpg"
        backgroundImageMobile="bg/packages-mobile@3x.jpg"
        // storeBadgeIcons
        meta={meta}
      >
        <PageSection
          css={css`
            margin-top: 75px;
          `}
        >
          <Container style={{ paddingTop: 60 }}>
            <div css={postContentCss} dangerouslySetInnerHTML={{ __html: generalTermsRS.node.content }} />
          </Container>
        </PageSection>
      </NewStandardPageLayout>
    );
  } else if (generalTermsRO && COUNTRY === 'ro') {
    return (
      <NewStandardPageLayout
        lang={props.pageContext.lang}
        mainHeading={t('tos_MainHeading')}
        subText={t('tos_MainSubText')}
        backgroundImage="bg/packages.jpg"
        backgroundImageMobile="bg/packages-mobile@3x.jpg"
        storeBadgeIcons
        meta={meta}
      >
        <PageSection
          css={css`
            margin-top: 75px;
          `}
        >
          <Container style={{ paddingTop: 60 }}>
            <div css={postContentCss} dangerouslySetInnerHTML={{ __html: generalTermsRO.node.content }} />
          </Container>
        </PageSection>
      </NewStandardPageLayout>
    );
  } else if (generalTermsBG && COUNTRY === 'bg') {
    return (
      <NewStandardPageLayout
        lang={props.pageContext.lang}
        mainHeading={t('tos_MainHeading')}
        subText={t('tos_MainSubText')}
        backgroundImage="bg/packages.jpg"
        backgroundImageMobile="bg/packages-mobile@3x.jpg"
        storeBadgeIcons
        meta={meta}
      >
        <PageSection
          css={css`
            margin-top: 75px;
          `}
        >
          <Container style={{ paddingTop: 60 }}>
            <div css={postContentCss} dangerouslySetInnerHTML={{ __html: generalTermsBG.node.content }} />
          </Container>
        </PageSection>
      </NewStandardPageLayout>
    );
  } else if (generalTerms) {
    return (
      <NewStandardPageLayout
        lang={props.pageContext.lang}
        mainHeading={t('tos_MainHeading')}
        subText={t('tos_MainSubText')}
        backgroundImage="bg/packages.jpg"
        backgroundImageMobile="bg/packages-mobile@3x.jpg"
        storeBadgeIcons
        meta={meta}
      >
        <PageSection
          css={css`
            margin-top: 75px;
          `}
        >
          <Container style={{ paddingTop: 60 }}>
            <div css={postContentCss} dangerouslySetInnerHTML={{ __html: generalTerms.node.content }} />
          </Container>
        </PageSection>
      </NewStandardPageLayout>
    );
  } else {
    return <PageNotFound />;
  }
};

export const allPostsQuery = graphql`
  query {
    allWordpressPage {
      edges {
        node {
          title
          slug
          date
          content
          id
        }
      }
    }
  }
`;

export default TermsPage;
